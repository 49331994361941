<template>
  <div class="pa-0">
    <!-- Carousel -->
    <v-carousel cycle height="100vh" progress hide-delimiters>
      <v-carousel-item v-for="items in banner" :key="items.id">
        <video-background
          v-if="items.image.extension == 'mp4'"
          :src="baseUrlVideo + items.image.file_path"
          :poster="require('@/assets/images/bg_sector.jpg')"
          :sources="[
            {
              src: baseUrlVideo + items.image.file_path,
              res: 900,
              autoplay: false,
            },
            {
              src: baseUrlVideo + items.image.file_path,
              res: 638,
              autoplay: false,
              poster: require('@/assets/images/bg_sector.jpg'),
            },
          ]"
          style="height: 100vh"
          overlay="rgba(31, 31, 31, 0.6)">
          <div class="grid items-center justify-items-center">
            <v-col cols="11" class="h-screen">
              <div
                class="grid md:grid-cols-2 items-center h-full pa-0 text-left text-white">
                <div class="pa-0">
                  <v-col cols="11">
                    <span
                      class="text-lg font-bold tracking-wide leading-snug sm:leading-snug sm:text-xl md:text-2xl lg:text-4xl">
                      {{ items.title }}
                    </span>
                  </v-col>
                  <v-col cols="11">
                    <span
                      class="font-lora text-base font-normal tracking-normal"
                      v-html="strLimit(items.desc, 150)">
                    </span>
                  </v-col>
                  <v-col>
                    <v-btn
                      class="btn-primary font-bold text-capitalize"
                      @click="goLink(items.link_button)">
                      <span>{{ items.title_button }}</span>
                    </v-btn>
                  </v-col>
                </div>
              </div>
            </v-col>
          </div>
        </video-background>
        <!-- :src="require('@/assets/images/bg_sector.jpg')" -->
        <v-img
          v-else
          :src="baseUrlVideo + items.image.file_path"
          @error="require('@/assets/images/bg_sector.jpg')"
          gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)">
          <div class="grid items-center justify-items-center">
            <v-col cols="11" class="h-screen">
              <div
                class="grid md:grid-cols-2 items-center h-full pa-0 text-left text-white">
                <div class="pa-0">
                  <v-col cols="11">
                    <span
                      class="text-lg font-bold tracking-wide leading-snug sm:leading-snug sm:text-xl md:text-2xl lg:text-4xl">
                      {{ items.title }}
                    </span>
                  </v-col>
                  <v-col cols="11">
                    <span
                      class="font-lora text-base font-normal tracking-normal"
                      v-html="strLimit(items.desc, 150)">
                    </span>
                  </v-col>
                  <v-col>
                    <v-btn
                      class="btn-primary font-bold text-capitalize"
                      @click="goLink(items.link_button)">
                      <span>{{ items.title_button }}</span>
                    </v-btn>
                  </v-col>
                </div>
              </div>
            </v-col>
          </div>
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <!-- End Carousel -->

    <!-- statistic -->
    <div
      class="white grid justify-items-center px-0 py-10"
      v-if="isLoadStatistic">
      <v-col cols="11" class="pa-0">
        <v-row class="px-3">
          <v-col cols="12" sm="4" v-for="i in 3" :key="i" class="text-left">
            <v-skeleton-loader
              type="paragraph"
              class="mt-3"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <div
      class="py-10 sm:block bg-primaryLight"
      v-else-if="statisticList.length > 0">
      <slick
        ref="slick"
        :options="slickOptionsStatus"
        class="lg:px-10 lg:mx-8 sm:px-8">
        <div
          v-for="(i, index) in statisticList"
          :key="index"
          class="px-10 mx-8 text-left">
          <div
            class="text-base py-2 pa-0 font-bold text-primary title-statistic">
            {{ i.title || "-" }}
          </div>
          <div class="text-sm pa-0 text-grey3">{{ i.unit || "-" }}</div>
          <div class="flex pa-0">
            <v-col cols="3" class="px-0">
              <v-icon
                class="text-base sm:text-2xl text-primary"
                large
                color="green darken-1"
                v-if="i.progress === 1"
                >arrow_upward</v-icon
              >
              <v-icon
                class="text-base sm:text-2xl text-primary"
                large
                color="red"
                v-if="i.progress === 0"
                >arrow_downward</v-icon
              >
            </v-col>
            <v-col cols="auto" class="text-center">
              <div
                class="text-3xl font-bold text-primary tracking-wide"
                :class="i.progress ? 'green--text' : 'red--text'">
                {{ formatCurrency(i.value) || "-" }}
              </div>
              <div class="text-sm py-1 pa-0 text-grey3 tracking-tight">
                {{ i.progress === 0 ? "-" : "" }} {{ i.value_progress || "-" }}%
                from {{ i.year || "-" }}
              </div>
            </v-col>
          </div>
        </div>
      </slick>
      <button
        class="px-3 py-3 btn-scroll font-medium ml-3 mb-10 nextStatus-btn">
        <v-icon color="white">arrow_back</v-icon>
      </button>
      <button
        class="px-3 py-3 btn-scroll font-medium ml-3 mb-10 backStatus-btn">
        <v-icon color="white">arrow_forward</v-icon>
      </button>
    </div>
    <!-- Latest program -->
    <div
      class="white grid justify-items-center px-0 py-10"
      v-if="programs.length > 0">
      <v-col cols="11" class="pa-0">
        <v-row class="px-3">
          <v-col cols="5" sm="6" class="text-left">
            <span class="font-bold text-2xl">
              <span v-if="bahasa">Program terbaru</span>
              <span v-else>Latest programs</span>
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              class="btn-primary-outlined font-bold text-capitalize"
              to="/project">
              <span v-if="bahasa">Semua program</span>
              <span v-else>All Programs</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <template v-for="(program, idxProgram) in programs">
            <v-col
              cols="12"
              sm="4"
              :key="idxProgram + 'program'"
              class="text-left cursor-pointer"
              @click="goTo('program-detail', program.id)"
              v-if="idxProgram < 3">
              <div class="font-bold text-xl pa-0">{{ program.name }}</div>
              <!-- <div class="text-sm py-2 pa-0">{{ program.createdDate }}</div> -->
              <div
                class="font-lora pa-0 text-base h-20"
                v-html="strLimit(program.desc || '-', 90)"></div>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </div>
    <!-- lates news -->
    <div
      class="grey lighten-4 grid justify-items-center px-0 py-10"
      v-if="news.length > 0">
      <v-col cols="11" class="pa-0">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-4">
            <span class="font-bold text-2xl">
              <span v-if="bahasa">Informasi Terbaru</span>
              <span v-else>Latest Information</span>
            </span>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col
            cols="12"
            sm="6"
            lg="7"
            class="text-left cursor-pointer"
            @click="goTo('news-detail', news[0].id)">
            <img
              :src="baseUrlImage + news[0].image.file_path"
              @error="setAltImg"
              width="100%"
              class="rounded-md" />
            <div class="font-bold text-2xl sm:text-3xl pa-0 mt-5">
              {{ news[0] ? news[0].title : "-" }}
            </div>
            <div class="text-sm py-2 pa-0">
              <span
                class="inline-flex items-center rounded-md bg-primary px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-primary-600/20">
                {{ news[0] ? news[0].category : "" }}
              </span>
              -
              {{ news[0] ? news[0].lastUpdate : "" }}
            </div>
            <div
              class="font-lora pa-0 text-base h-24 sm:h-14"
              v-html="strLimit(news[0].desc, 100)"></div>
            <v-btn
              class="btn-primary mt-3 font-bold text-capitalize"
              @click="goTo('news-detail', news[0].id)">
              <span v-if="bahasa">baca selengkapnya</span>
              <span v-else>Read More</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" lg="5" class="text-left pt-0">
            <div
              class="flex mb-2 cursor-pointer"
              v-for="(berita, idxNews) in news"
              :key="idxNews + 'news'"
              @click="goTo('news-detail', berita.id)">
              <template v-if="idxNews > 0 && idxNews < 5">
                <v-col cols="3" class="px-0">
                  <img
                    :src="baseUrlImage + berita.image.file_path"
                    @error="setAltImg"
                    width="100%"
                    class="rounded-md sm:px-3" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-base pt-0 sm:text-xl">
                    {{ berita.title }}
                  </div>
                  <div class="text-sm py-2 pa-0">
                    <span
                      class="inline-flex items-center rounded-md bg-primary px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-primary-600/20">
                      {{ berita ? berita.category : "" }}
                    </span>
                    -
                    {{ berita.lastUpdate }}
                  </div>
                </v-col>
              </template>
            </div>
            <v-btn
              class="btn-primary-outlined mt-5 font-bold text-capitalize sm:ml-3"
              v-if="news.length > 1"
              to="/publication">
              <span v-if="bahasa">lihat berita lalu</span>
              <span v-else>See older news</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <!-- Stories -->
    <!-- <div
      class="white grid justify-items-center px-0 py-10"
      v-if="stories.length > 0">
      <v-col cols="11" class="pa-0">
        <v-row class="px-3 mb-8">
          <v-col cols="5" lg="6" class="text-left">
            <span class="font-bold text-2xl">
              <span>{{ bahasa ? "Kisah" : "Stories" }}</span>
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              class="btn-primary-outlined font-bold text-capitalize"
              :to="{ name: 'publication' }">
              <span>{{ bahasa ? "semua kisah" : "All Stories" }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <div class="sm:px-2">
          <v-row class="px-3">
            <v-col cols="12" sm="6" lg="7" class="text-left pa-0 mb-5">
              <v-img
                :src="baseUrlImage + stories[0].image.file_path"
                class="rounded-l-lg align-end pa-5 w-screen"
                height="500">
                <v-col
                  cols="12"
                  sm="10"
                  lg="7"
                  class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                  <div class="text-sm">{{ bahasa ? "Kisah" : "Stories" }}</div>
                  <div class="text-2xl tracking-wide py-2">
                    {{ stories[0].title || "-" }}
                  </div>
                  <v-btn
                    class="btn-primary-white mt-3 font-bold text-capitalize"
                    @click="
                      goToContent('content-detail', stories[0].id, 'kisah')
                    ">
                    {{ bahasa ? "Baca Selengkapnya" : "Read More" }}
                  </v-btn>
                </v-col>
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" lg="5" class="pa-0">
              <v-col cols="12" class="text-left pa-0">
                <v-img
                  :src="baseUrlImage + stories[1].image.file_path"
                  class="rounded-tr-lg align-end pa-5 w-screen"
                  height="250">
                  <v-col
                    cols="12"
                    class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                    <div class="text-sm">
                      {{ bahasa ? "Kisah" : "Stories" }}
                    </div>
                    <div class="text-2xl tracking-wide py-2">
                      {{ stories[1].title || "-" }}
                    </div>
                    <v-btn
                      class="btn-primary-white mt-3 font-bold text-capitalize"
                      @click="
                        goToContent('content-detail', stories[1].id, 'kisah')
                      ">
                      {{ bahasa ? "Baca Selengkapnya" : "Read More" }}
                    </v-btn>
                  </v-col>
                </v-img>
              </v-col>
              <v-col cols="12" class="sm:hidden pa-0 mt-5"> </v-col>
              <v-col cols="12" class="text-left pa-0" v-if="stories.length > 1">
                <v-img
                  :src="baseUrlImage + stories[2].image.file_path"
                  class="rounded-tr-lg align-end pa-5 w-screen"
                  height="250">
                  <v-col
                    cols="12"
                    class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                    <div class="text-sm">
                      {{ bahasa ? "Kisah" : "Stories" }}
                    </div>
                    <div class="text-2xl tracking-wide py-2">
                      {{ stories[2].title || "-" }}
                    </div>
                    <v-btn
                      class="btn-primary-white mt-3 font-bold text-capitalize"
                      @click="
                        goToContent('content-detail', stories[2].id, 'kisah')
                      ">
                      {{ bahasa ? "Baca Selengkapnya" : "Read More" }}
                    </v-btn>
                  </v-col>
                </v-img>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </div> -->
    <!-- Upcoming events -->
    <div class="py-10 white" v-if="events.length > 0">
      <div class="grid justify-items-center pa-0">
        <v-col cols="11" class="pa-0">
          <v-row class="px-3 mb-5">
            <v-col cols="5" sm="6" class="text-left">
              <span class="font-bold text-2xl">
                <span v-if="bahasa">Acara mendatang</span>
                <span v-else>Upcoming events</span>
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                class="btn-primary-outlined font-bold text-capitalize"
                to="/events">
                <span v-if="bahasa">semua acara</span>
                <span v-else>All events</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="hidden sm:block" v-if="events.length > 0">
        <slick ref="slick" :options="slickOptions" class="px-10">
          <div
            v-for="(event, idxEvent) in events"
            :key="'itemEvent-' + idxEvent"
            class="px-6 text-left cursor-pointer"
            @click="goTo('event-detail', event.id)">
            <img
              :src="baseUrlImage + event.image.file_path"
              width="100%"
              @error="$event.target.src = ''"
              class="rounded-md img-card-event" />
            <div class="font-bold text-base sm:text-xl py-2">
              {{ event.name || "-" }}
            </div>
            <div class="text-sm pb-2 pa-0">
              {{
                event.start_date === event.end_date
                  ? event.start_date
                  : event.start_date + " - " + event.end_date
              }}
            </div>
            <div class="font-lora pa-0 text-base h-24 sm:h-14">
              <div v-html="strLimit(event.desc, 200)" />
            </div>
          </div>
        </slick>
        <button class="btn-white-outlined next-btn">
          <v-icon color="black">arrow_back</v-icon>
        </button>
        <button class="btn-white-outlined back-btn">
          <v-icon color="black">arrow_forward</v-icon>
        </button>
      </div>
      <div class="sm:hidden">
        <slick ref="slick" :options="slickOptionsXS" class="px-5">
          <div v-for="i in 9" :key="i" class="px-6 text-left">
            <img
              src="@/assets/images/cover.png"
              width="100%"
              class="rounded-md" />
            <div class="font-bold text-base sm:text-xl py-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing
            </div>
            <div class="font-lora pa-0 text-base h-24 sm:h-14">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ...
            </div>
          </div>
        </slick>
        <button class="btn-white-outlined next-btn-xs">
          <v-icon color="black">arrow_back</v-icon>
        </button>
        <button class="btn-white-outlined back-btn-xs">
          <v-icon color="black">arrow_forward</v-icon>
        </button>
      </div>
    </div>
    <!-- Lates reports -->
    <div
      class="white lighten-4 grid justify-items-center px-0 py-10"
      v-if="reports.length > 0">
      <v-col cols="11" class="pa-0">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-4">
            <span class="font-bold text-2xl">
              <span v-if="bahasa">Laporan terbaru</span>
              <span v-else>Latest reports</span>
            </span>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <template v-for="(report, idxReport) in reports">
            <v-col
              cols="12"
              sm="6"
              :key="idxReport + 'report'"
              class="text-left cursor-pointer"
              @click="goTo('report-detail', report.id)"
              v-if="idxReport < 2">
              <img
                :src="baseUrlImage + report.image.file_path"
                @error="setAltImg"
                width="100%"
                class="rounded-md img-card-report" />
              <div class="font-bold text-2xl sm:text-3xl pa-0 mt-5">
                {{ report.title || "-" }}
              </div>
              <!-- <div class="text-sm py-2 pa-0">{{ report.date || "-" }}</div> -->
              <div class="font-lora pa-0 text-base sm:h-32 py-2 mt-5">
                <div v-html="strLimit(report.desc, 200)" />
              </div>
              <v-btn
                class="btn-primary-outlined mt-3 font-bold text-capitalize"
                @click="goTo('report-detail', report.id)">
                <span v-if="bahasa">Baca selengkapnya</span>
                <span v-else>Read More</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </div>
    <!-- FAQ -->
    <div class="white pa-0" id="faq" v-if="faqList.length > 0">
      <div class="flex">
        <v-col cols="12" sm="9" class="py-10">
          <div class="grid justify-items-center px-0 py-10">
            <v-col cols="10" class="pa-0">
              <v-row class="px-0">
                <v-col cols="12" class="text-left px-0">
                  <span class="font-bold text-2xl">
                    <span v-if="bahasa">Tanya jawab</span>
                    <span v-else>FAQ</span>
                  </span>
                </v-col>
              </v-row>
              <v-row class="px-0">
                <v-skeleton-loader
                  v-if="isLoadFaq"
                  type="paragraph"
                  class="mt-3"></v-skeleton-loader>
                <v-expansion-panels flat v-else>
                  <v-expansion-panel v-for="faq in faqList" :key="faq.id">
                    <v-expansion-panel-header
                      class="expansion-qna font-bold text-lg text-capitalize">
                      {{ faq.question }}
                      <template v-slot:actions>
                        <v-icon large color="grey darken-4">expand_more</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="expansion-qna font-lora text-left">
                      <div v-html="faq.answer" />
                    </v-expansion-panel-content>
                    <hr class="hr-expansion" />
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="pa-0 hidden sm:block">
          <img src="@/assets/images/bg_qna.svg" />
        </v-col>
      </div>
    </div>
    <progress-dialog :show="isLoadBanner"> </progress-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Slick from "vue-slick";
import VideoBackground from "vue-responsive-video-background-player";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
export default {
  components: {
    "video-background": VideoBackground,
    Slick,
  },
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      storiesImage: [],
      isLoadBanner: false,
      isLoadFaq: false,
      isLoadStatistic: false,
      isLoadStory: false,
      faqList: [],
      statisticList: [],
      slickOptions: {
        slidesToShow: 3,
        prevArrow: ".next-btn",
        nextArrow: ".back-btn",
      },
      slickOptionsXS: {
        slidesToShow: 1,
        prevArrow: ".next-btn-xs",
        nextArrow: ".back-btn-xs",
      },
      slickOptionsStatus: {
        slidesToShow: 4,
        prevArrow: ".nextStatus-btn",
        nextArrow: ".backStatus-btn",
        dots: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsStatusXS: {
        slidesToShow: 1,
        prevArrow: ".nextStatusXS-btn",
        nextArrow: ".backStatusXS-btn",
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      banner: {},
      baseUrlVideo: process.env.VUE_APP_BASE_ASSET,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      linkVideo: "",
      programs: [],
      news: [],
      events: [],
      reports: [],
      stories: [],
      blogs: [],
    };
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    language() {
      this.getData();
    },
    stories(val) {
      this.stories = val || [];
    },
    blogs(val) {
      this.blogs = val || [];
    },
    statisticList(val) {
      this.statisticList = val || [];
    },
    faqList(val) {
      this.faqList = val || [];
    },
    programs(val) {
      this.programs = val || [];
    },
    news(val) {
      this.news = val || [];
    },
    events(val) {
      this.events = val || [];
    },
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.getBanner();
      await this.getStatistic();
      await this.getProgram();
      await this.getFaq();
      await this.getNews();
      await this.getEvents();
      await this.getReports();
      await this.getDataStory();
      // await this.getDataBlog();
    },
    async getBanner() {
      this.isLoadBanner = true;
      await axios
        .get(this.baseUrl + "/banner", { params: { lang: this.language } })
        .then((response) => {
          if (response.data.data) {
            this.banner = response.data.data;
          } else {
            this.banner = {
              0: {
                desc: "Default Banner",
                image: {
                  id: "26c33fbc-d18c-4536-9c56-219cdf717c4a",
                  group: "editor",
                  visibility: "private",
                  file_name: "21ed7da7-4d9e-4e4f-9f3d-79212f3ee259.jpg",
                  file_path:
                    "uploads/2024/02/21ed7da7-4d9e-4e4f-9f3d-79212f3ee259.jpg",
                  fileable_id: "aa307c65-7bf7-4874-b633-d1aef983da89",
                  extension: "jpg",
                },
                link_button: "bpdlh.id/about-us",
                title: "Default Banner",
                title_button: "Tentang Kami",
              },
            };
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBanner = false));
    },
    async getFaq() {
      this.isLoadFaq = true;
      await axios
        .get(this.baseUrl + "/faq", { params: { lang: this.language } })
        .then((response) => {
          if (response.data.code === 200) {
            this.faqList = response.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadFaq = false));
    },
    async getStatistic() {
      this.isLoadStatistic = true;
      await axios
        .get(this.baseUrl + "/statistic", { params: { lang: this.language } })
        .then((response) => {
          if (response.data.code === 200) {
            this.statisticList = response.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadStatistic = false));
    },
    async getProgram() {
      this.isLoadBanner = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/program", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.programs = response.data.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBanner = false));
    },
    async getNews() {
      this.isLoadBanner = true;
      let param = {
        lang: this.language,
        // type: "berita",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          this.news = response.data.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBanner = false));
    },
    goTo(name, id) {
      this.$router.push({
        name: name,
        params: {
          id: id,
        },
      });
    },
    goLink(url) {
      window.open(url);
    },
    goToContent(link, param, type) {
      this.$router.push({
        name: link,
        params: {
          id: param,
          type: "/" + type,
        },
      });
    },
    async getEvents() {
      this.isLoadBanner = true;
      let param = {
        lang: this.language,
        option: "NEW",
      };
      await axios
        .get(this.baseUrl + "/event", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.events = response.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBanner = false));
    },
    async getReports() {
      this.isLoadBanner = true;
      await axios
        .get(this.baseUrl + "/report", { params: { lang: this.language } })
        .then((response) => {
          this.reports = response.data.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBanner = false));
    },
    async getDataStory() {
      this.isLoadStory = true;
      let param = {
        lang: this.language,
        type: "kisah",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.stories = response.data.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadStory = false));
    },
    async getDataBlog() {
      this.isLoadStory = true;
      let param = {
        lang: this.language,
        type: "siaran-pers",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.blogs =
              response.data.data.data.length > 2
                ? response.data.data.data.slice(0, 2)
                : response.data.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadStory = false));
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
      console.log("handleAfterChange", event, slick, currentSlide);
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      console.log("handleBeforeChange", event, slick, currentSlide, nextSlide);
    },
    handleBreakpoint(event, slick, breakpoint) {
      console.log("handleBreakpoint", event, slick, breakpoint);
    },
    handleDestroy(event, slick) {
      console.log("handleDestroy", event, slick);
    },
    handleEdge(event, slick, direction) {
      console.log("handleEdge", event, slick, direction);
    },
    handleInit(event, slick) {
      console.log("handleInit", event, slick);
    },
    handleReInit(event, slick) {
      console.log("handleReInit", event, slick);
    },
    handleSetPosition(event, slick) {
      console.log("handleSetPosition", event, slick);
    },
    handleSwipe(event, slick, direction) {
      console.log("handleSwipe", event, slick, direction);
    },
    handleLazyLoaded(event, slick, image, imageSource) {
      console.log("handleLazyLoaded", event, slick, image, imageSource);
    },
    handleLazeLoadError(event, slick, image, imageSource) {
      console.log("handleLazeLoadError", event, slick, image, imageSource);
    },
    setAltImg(event) {
      let src = "https://admin.bpdlh.id/assets/empty.png";
      event.target.src = src;
    },
    onImageError(index) {
      // console.log(this.url);
      // this.storiesImage = "https://admin.bpdlh.id/assets/empty.png";
      // // event.target.src = require("@/assets/images/404.png");
      // $event.target.src = "https://admin.bpdlh.id/assets/empty.png";
      this.stories[index].image.file_path =
        "https://admin.bpdlh.id/assets/empty.png";
      this.$forceUpdate();
      // console.log(event);
    },
    setStoriesImage(index) {
      this.storiesImage[index] = this.stories[index].image.file_path;
      return this.storiesImage[index];
    },
  },
};
</script>
<style></style>
